const setup = function () {

  if(document.readyState !== 'loading') {
    initSite();
  } else {
    document.addEventListener('DOMContentLoaded', function () {
      initSite();
    });
  }
  function initSite()
  {
    if (document.querySelectorAll('.imgFullCover').length > 0) {
      import(/* webpackPrefetch: true */ './modules/ImgFullCover').then(({default: ImgFullCover}) => {
        ImgFullCover.init(document.querySelectorAll('.imgFullCover'));
      });
    }

    import(/* webpackPrefetch: true */ './modules/MobileMenu').then(({default: MobileMenu}) => {
      MobileMenu.init();
    })
    if (window.matchMedia('(max-width: 991px)').matches) {
      initializeMobileMenu();
    }
    else {
      import(/* webpackPrefetch: true */ './modules/Menu').then(({default: Menu}) => {
        Menu.initializeMenubar(document.getElementById('main-nav'));
        Menu.handleAccessibility(document.getElementById('header'));
      });
    }

    import(/* webpackPrefetch: true */ './modules/LinkHandler').then(({default: LinkHandler}) => {
      LinkHandler.initSmoothScrollOnHashClick();
      LinkHandler.initSkiplinks();
    });

    import(/* webpackPrefetch: true */ './modules/GeneralUtility').then(({default: GeneralUtility}) => {
      GeneralUtility.lockMousewheelOnNumberInputs();
      GeneralUtility.initializeResponsiveTables(document.querySelectorAll('table.contenttable, .ce-table'));
    });

    if(document.querySelectorAll('.lightbox').length > 0) {
      import(/* webpackPrefetch: true */ 'GLightbox').then(({ default: GLightbox }) => {
        new GLightbox({
          selector: '.lightbox',
          touchNavigation: true,
          loop: true,
          autoplayVideos: true,
        });
      })
    }

    if(document.querySelectorAll('lite-vimeo').length > 0) {
      import(/* webpackPrefetch: true */ '@slightlyoff/lite-vimeo');
    }

    if(document.querySelectorAll('lite-youtube').length > 0) {
      import(/* webpackPrefetch: true */ '@justinribeiro/lite-youtube');
    }

    document.querySelectorAll('.printer').forEach(function(printButton){
      printButton.addEventListener('click', function(){
        window.print();
        return false;
      });
    })

    document.querySelector('.openSearch').addEventListener('click', function(e){
      e.preventDefault();
      e.stopImmediatePropagation();
      if(e.target.closest('.metaNav').classList.contains('searchBoxOpen'))
      {
        e.target.parentElement.querySelector("form").submit();
      }
      else {
        e.target.closest('.metaNav').classList.add('searchBoxOpen');
        if(window.matchMedia('(max-width: 575px)').matches) {
          document.querySelector('#logo').style.opacity = 0;
        }
        e.target.parentElement.querySelector("form input[type='text']").focus();
        e.target.parentElement.querySelector("form input[type='text']").addEventListener('focusout', function (e) {
          setTimeout(function(element){
            element.closest('.metaNav').classList.remove('searchBoxOpen');
            document.querySelector('#logo').style.opacity = 1;
          }, 500, e.target)
        })
      }
    });
    document.addEventListener('click', event => {
      const isClickInside = document.querySelector('.search').contains(event.target)
      if (!isClickInside) {
        document.querySelector('.search').closest('.container').classList.remove('searchBoxOpen');
      }
    })

    if(window.matchMedia('(max-width: 991px)').matches && document.querySelectorAll(' .tx-contentblocks .background-grey').length > 0)
    {
      import(/* webpackPrefetch: true */ 'swiper').then(({default: Swiper, Pagination }) => {
        document.querySelectorAll(' .tx-contentblocks .background-grey').forEach(function(slider){
          new Swiper(slider, {
            arrows: false,
            autoplay: false,
            speed: 1500,
            dots: true,
            modules: [Pagination]
          });
        });
      });
    }

    if(window.matchMedia('(max-width: 991px)').matches && document.querySelectorAll(' .quotes-slider').length > 0)
    {
      import(/* webpackPrefetch: true */ 'swiper').then(({default: Swiper}) => {
        document.querySelectorAll('.quotes-slider').forEach(function(slider){
          new Swiper(slider, {
            breakpoints: {
              480: {
                slidesToShow: 2
              },
              320: {
                slidesToShow: 1
              },
            },
          });
        });
      });
    }

    if(document.querySelectorAll(' .yellow-slider').length > 0)
    {
      import(/* webpackPrefetch: true */ 'swiper').then(({default: Swiper, Navigation}) => {
        document.querySelectorAll(' .yellow-slider .swiper-slider').forEach(function(slider){
          new Swiper(slider, {
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
            modules: [Navigation],
            autoplay: false,
            loo: true,
            watchOverflow: true,
            breakpoints: {
              992: {
                adaptiveHeight: false
              },
              320: {
                adaptiveHeight: true
              },
            },
          });
        });
      });
    }

    document.querySelector('.openSearch').addEventListener('click', function(e) {
      e.preventDefault();
      let click = document.createEvent('Events');
      click.initEvent('click', true, false);
      document.querySelector('.search').dispatchEvent(click);
    });

    document.querySelectorAll('a').forEach(function(link){
      link.addEventListener('keyup', function(e){
        e.target.classList.add("focusOutline");
      })
      link.addEventListener('blur', function(e){
        e.target.classList.remove("focusOutline");
      });
    });

    if(document.querySelectorAll('.tx-filelist table').length > 0) {
      import(/* webpackPrefetch: true */ 'tablesorter').then(({default: Tablesorter}) => {
        import(/* webpackPrefetch: true */ 'tablesorter/dist/css/theme.default.min.css')
        document.querySelectorAll('.tx-filelist table').forEach(function (table) {
          Tablesorter.setup(table, Tablesorter.defaults);
        });
      });
      document.querySelectorAll('.tx-filelist td').forEach(function (cell) {
        if(cell.innerHTML.trim() == "Keine Datei") {
          cell.innerHTML = '';
        }
      });
    }

    // if(document.querySelectorAll('.constrainZoom').length > 0) {
    //   import(/* webpackPrefetch: true */ '@zeitiger/elevatezoom').then(({default: ElevateZoom}) => {
    //     document.querySelectorAll('.constrainZoom').forEach(function (zoom) {
    //       ElevateZoom.init({constrainType:"height", constrainSize:274, zoomType: "lens", containLensZoom: true}, zoom)
    //     });
    //   });
    // }

    if(window.matchMedia('(max-width: 767px)').matches)
    {
      document.querySelectorAll('.banner-quote').forEach(function(banner){
        banner.addEventListener('click', function(e){
          e.target.classList.toggle('showOverlay');
        })
      })
    }

    document.querySelectorAll('.cursor-default').forEach(function(cursor){
      cursor.addEventListener('click', function(e){
        e.preventDefault();
      })
    })
  }

  window.addEventListener('resize', function () {
    import(/* webpackPrefetch: true */ './modules/ImgFullCover').then(({default: ImgFullCover}) => {
      ImgFullCover.init(document.querySelectorAll('.imgFullCover'));
    });
    if (window.matchMedia('(max-width: 991px)').matches) {
      if(!document.querySelector('.main-navigation').classList.contains('mobile')) {
        initializeMobileMenu();
      }
    } else {
      if(document.querySelector('.main-navigation').classList.contains('mobile')) {
        restoreDesktopMenu();
      }
    }
    document.querySelectorAll('.background-grey').forEach(function(grey){
      grey.querySelector('.innerWidth').style.width = window.innerWidth + 'px';
    });
  }, {passive: true});

  window.addEventListener('scroll', function () {
    if(window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0)
    {
      document.querySelector('.header-top').classList.add('shadow');
    }
    else
    {
      document.querySelector('.header-top').classList.remove('shadow');
    }
    if(document.querySelectorAll('#mobileNav').length > 0 && document.querySelector('#mobileNav').classList.contains('open')) {
      document.body.style.height = window.innerHeight + 'px';
    }
  }, {passive: true});

  window.addEventListener('load', function () {
    if(document.getElementById('to-top'))
    {
      import(/* webpackPrefetch: true */ './modules/ToTop').then(({ default: ToTop }) => {
        ToTop.init(document.getElementById('to-top'));
      });
    }
    if(window.location.hash.length > 0) {
      import(/* webpackPrefetch: true */ './modules/AutoScrollToHash').then(({ default: AutoScrollToHash }) => {
        AutoScrollToHash.autoscroll(document.getElementById('cms-content'), document.getElementById('header').outerHeight, 750);
      }, {passive: true})
    }
    document.querySelectorAll('.background-grey').forEach(function(grey){
      let wrapper = document.createElement('div');
      wrapper.outerHTML = '<div class="fullWidth"><div class="innerWidth"><div class="color">';
      grey.parentNode.insertBefore(wrapper, grey);
      wrapper.querySelector('.color').appendChild(grey);
      grey.querySelector('.innerWidth').style.width = window.innerWidth + 'px';
      grey.querySelector('.color').style.height = grey.clientHeight + 'px';
    });
  }, {passive: true});
};

let desktopMainNavHtml = null;
let mobileMainNavHtml = null;

const storeDesktopMainNavHtml = function() {
  if (desktopMainNavHtml == null) {
    desktopMainNavHtml = document.getElementById('main-nav').parentElement.outerHTML;
  }
};

const replaceMobileNavigation = function(html) {
  document.getElementById('main-nav').parentElement.outerHTML = html.outerHTML;

  document.querySelector('.mobileMenu').classList.remove('loading');
  document.querySelector('.mobileMenu .mobileMenuButton').setAttribute('title', '');

  let currentPageUid = document.querySelector('#site').getAttribute('data-pid');
  document.querySelectorAll('#main-nav li[data-uid="' + currentPageUid + '"]').forEach(function (item) {
    while(item)
    {
      item.classList.add('active');
      item.querySelector('a').classList.add('active');
      // item.querySelector('a').setAttribute('aria-expanded', true);
      item = item.parentElement.closest('li');
    }
  });

  import(/* webpackPrefetch: true */ './modules/Menu').then(({default: Menu}) => {
    Menu.handleAccessibility(document.getElementById('header'));
  });
};

const initializeMobileMenu = function() {
  storeDesktopMainNavHtml();

  if (mobileMainNavHtml === null) {
    if(!document.querySelector('.mobileMenu').classList.contains('loading')) {
      document.querySelector('.mobileMenu').classList.add('loading');
      let ajaxMenu = new XMLHttpRequest();
      ajaxMenu.onreadystatechange = function () {
        if (ajaxMenu.readyState === 4) {
          if (ajaxMenu.status === 200) {
            let parser = new DOMParser();
            replaceMobileNavigation(parser.parseFromString(ajaxMenu.responseText, 'text/html').documentElement.querySelector('nav'));
            mobileMainNavHtml = document.getElementById('main-nav').parentElement.outerHTML;
          }
          else {
            document.querySelector('.mobileMenu').classList.remove('loading');
            document.querySelector('.mobileMenu .mobileMenuButton').setAttribute('title', '');

            import(/* webpackPrefetch: true */ './modules/Menu').then(({default: Menu}) => {
              Menu.handleAccessibility(document.getElementById('header'));
              Menu.initializeMenubar(document.getElementById('main-nav'));
            });
          }
        }
      }
      ajaxMenu.open('Get', document.querySelector('#site').getAttribute('data-ajaxMenu'));
      ajaxMenu.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
      ajaxMenu.send();
    }
  } else {
    let parser = new DOMParser();
    replaceMobileNavigation(parser.parseFromString(mobileMainNavHtml, 'text/html').documentElement.querySelector('nav'));
  }
};

const restoreDesktopMenu = function() {
  if(desktopMainNavHtml !== null) {
    let parser = new DOMParser();
    document.getElementById('main-nav').parentElement.outerHTML = parser.parseFromString(desktopMainNavHtml, 'text/html').documentElement.querySelector('nav').outerHTML;
    import(/* webpackPrefetch: true */ './modules/Menu').then(({default: Menu}) => {
      Menu.handleAccessibility(document.getElementById('header'));
      Menu.initializeMenubar(document.getElementById('main-nav'));
    });
  }
};

export default {
  setup
};
